import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMutation, useManualQuery, ClientContext } from "graphql-hooks";

import {
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  TextField,
  Radio,
  Checkbox,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import Box from "@mui/material/Box";


const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "50px",
    textAlign: "center",
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    width: "1614px",
    paddingTop: "9rem", //"7.5rem",
    //  minWidth: "500px",
  },
  container: {
    padding: theme.spacing(2),
    paddingTop:30,
    marginTop: "1rem",
    marginBottom: "5rem",
    overflow: "hidden",
    clear: "both",
    borderRadius: "8px",
    height: "902px",
    backgroundColor: "#ffffff",
  },
}));

function ToBeDev() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="h1">To be developped</Typography>
      </div>
    </div>
  );
}

export default ToBeDev;
