import React, { useState } from "react";
import { Button, Typography, IconButton, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles(theme => ({
  outlinedButtonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#0D47A1",
    height: "34px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#fff",
    height: "34px",
    backgroundColor: "#0D47A1",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#0D47A1",
    },
    "&:hover": {
      backgroundColor: "#1E88E5",
    },
  },
}));

export default function AlertDialog({
  title,
  contentMsg,
  type,
  confirmAction,
  confirmButton,
  closeDialog,
  headerMsg,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    closeDialog && closeDialog();
  };
  const handleConfirm = () => {
    setOpen(false);
    confirmAction && confirmAction();
  };

  return (
    <div style={{ width: "90%" }}>
      {type && type === "Confirmation" && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="alert-dialog-title"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box mt={1} ml={1}>
              {title}
            </Box>
            {closeDialog ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  align: "right",
                  color: theme => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent style={{ textAlign: "Left" }}>
            <DialogContentText id="alert-dialog-description">
              {contentMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Box mb={2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                style={{ marginRight: 5 }}
                onClick={confirmAction ? handleConfirm : handleClose}
              >
                {confirmButton}
              </Button>
              <Button
                variant="text"
                color="primary"
                className={classes.outlinedButtonStyle}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}


      {!type && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onKeyDown={e => {
            e.key === "Enter" && handleClose();
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="alert-dialog-title"
            style={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: 300,
            }}
          >
            <Box mt={1} ml={1}>
              {title}
            </Box>
            {closeDialog ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  align: "right",
                  color: theme => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          {headerMsg && (
            <DialogContent
              style={{
                paddingBottom: 0,
              }}
            >
              <DialogContentText
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                }}
                id="alert-dialog-description"
              >
                {headerMsg}
              </DialogContentText>
            </DialogContent>
          )}
          <DialogContent
            style={{
              paddingTop: 0,
            }}
          >
            <DialogContentText id="alert-dialog-description">
              {contentMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Box mb={2}>
              <Button
                variant="text"
                onClick={handleClose}
                color="primary"
                className={classes.buttonStyle}
              >
                OK
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
