import React, { useState, useEffect } from "react";
import { Tooltip, Box, makeStyles } from "@material-ui/core";

import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsIcon from "@mui/icons-material/Settings";
import FolderIcon from "@mui/icons-material/Folder";
import HomeIcon from "@mui/icons-material/Home";
import ClientsIcon from "@mui/icons-material/AccountTree";
import FormsIcon from "@mui/icons-material/ListAlt";
import TermsIcon from "@mui/icons-material/School";
import VolunteersIcon from "@mui/icons-material/VolunteerActivism";
import SprintsIcon from "@mui/icons-material/Speed";
import GradingIcon from "@mui/icons-material/Grading";

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: "0px, -50px, 20px, -25px",
          },
        },
      },
    }}
  />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
  }
`;
const useStyles = makeStyles((theme) => ({}));

export default function SideMenu({
  subheader,
  items,
  href,
  icon,
  menuStatus,
  menuStatusFunc,
  subMenu,
  subMenuFunc,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(subheader === menuStatus.expand);

  useEffect(() => {
    setOpen(subheader === menuStatus.expand || subMenu.menu === subheader);
  }, [subheader, menuStatus.expand, subMenu]);

  const handleClick = async () => {
    if (items.length === 0) {
      href && navigate(href);
      menuStatusFunc({ simple: subheader, expand: null });
      subMenuFunc({ menu: subheader, sub: null });
    }
    setOpen(!open);
  };

  return (
    <List
      disableRipple
      sx={{
        "& .MuiTypography-root": {
          fontSize: 16,
        },
        color: "#0D47A1",
        "& .MuiSvgIcon-root": {
          color: "#0D47A1",
        },
        "& .MuiListItemIcon-root": {
          minWidth: "34px !important",
        },

        "& .MuiListItemText-primary": {
          color: "#0D47A1",
        },
        "& .MuiListItemButton-root:hover": {
          "& .MuiListItemText-primary": {
            color: "#fff",
          },
          "& .MuiSvgIcon-root": {
            color: menuStatus.simple ? "#0D47A1" : "#fff",
          },
          backgroundColor: menuStatus.simple ? "#fff" : "#0D47A1",
          borderRadius: "4px",
          color: "#fff",
        },
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton
        sx={{
          // pl: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: menuStatus.simple ? "center" : "",
        }}
        onClick={handleClick}
        disableGutters
        disableRipple
      >
        {menuStatus.simple ? (
          <StyledTooltip title={subheader}>
            <ListItemIcon>
               <IconButton
                style={{
                  padding: menuStatus.simple === subheader ? 5 : "",
                  borderRadius:
                    menuStatus.simple === subheader ? "100%" : "none",
                  border:
                    menuStatus.simple === subheader ? "3px solid" : "none",
                  borderColor:
                    menuStatus.simple === subheader ? "#F4511E" : "none",
                }}
                onClick={async () => {
                  if (items.length == 0) {
                    menuStatusFunc({ simple: subheader, expand: null });
                    return;
                  }
                  menuStatusFunc({ simple: null, expand: subheader });
                }}
              >
                {icon === "Home" && <HomeIcon />}
                {!icon && <FolderIcon />}
                {icon === "Settings" && <SettingsIcon />}
                {icon === "Users" && <GroupsIcon />}
                {icon === "Profile" && <AccountCircleIcon />}
                {icon === "Clients" && <ClientsIcon />}
                {icon === "Forms" && <FormsIcon />}
                {icon === "Terms" && <TermsIcon />}
                {icon === "Volunteers" && <VolunteersIcon />}
                {icon === "Sprints" && <SprintsIcon />}
                {icon === "Gradings" && <GradingIcon />}
              </IconButton>
            </ListItemIcon>
          </StyledTooltip>
        ) : (
          <ListItemIcon>
            <IconButton
              style={{
                padding: menuStatus.simple === subheader ? 5 : "",
                borderRadius: menuStatus.simple === subheader ? "100%" : "none",
                border: menuStatus.simple === subheader ? "3px solid" : "none",
                borderColor:
                  menuStatus.simple === subheader ? "#F4511E" : "none",
              }}
            >
              {icon === "Home" && <HomeIcon />}
              {!icon && <FolderIcon />}
              {icon === "Settings" && <SettingsIcon />}
              {icon === "Users" && <GroupsIcon />}
              {icon === "Profile" && <AccountCircleIcon />}
              {icon === "Clients" && <ClientsIcon />}
              {icon === "Forms" && <FormsIcon />}
              {icon === "Terms" && <TermsIcon />}
              {icon === "Volunteers" && <VolunteersIcon />}
              {icon === "Sprints" && <SprintsIcon />}
              {icon === "Gradings" && <GradingIcon />}
            </IconButton>
          </ListItemIcon>
        )}

        {!menuStatus.simple && (
          <ListItemText style={{ paddingTop: 5 }} primary={subheader} />
        )}
        {items.length !== 0 && !menuStatus.simple ? (
          open ? (
            <KeyboardArrowUpIcon
              sx={{
                paddingLeft: "8px",
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              sx={{
                paddingLeft: "8px",
              }}
            />
          )
        ) : (
          ""
        )}
      </ListItemButton>
      {subheader === "Dashboard" && (
        <Divider style={{ width: "100%" /*,borderStyle: "hidden" */ }} />
      )}
      {!menuStatus.simple &&
        items.map((item, index) => (
          <Collapse in={open} autoFocus={open}>
            <List
              sx={{
                "& .MuiTypography-root": {
                  fontSize: 14,
                },
              }}
              key={`list${index}`}
              component="div"
              disablePadding
              autoFocus={open}
            >
              <ListItemButton
                disableRipple
                sx={{ pl: "50px" }}
                onClick={() => {
                  menuStatusFunc({ simple: subheader, expand: null });
                  subMenuFunc({ menu: subheader, sub: item.title });
                  item.href && navigate(item.href);
                }}
              >
                {!menuStatus.simple && (
                  <ListItemText
                    primary={item.title}
                    onClick={() => item.href && navigate(item.href)}
                    style={{
                      maxWidth: "max-content",
                      minWidth: 50,
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 2,
                      borderRadius: 4,
                      border:
                        subMenu.menu === subheader && subMenu.sub === item.title
                          ? "3px solid #F4511E"
                          : "none",
                    }}
                  />
                )}
              </ListItemButton>
            </List>
          </Collapse>
        ))}
    </List>
  );
}
