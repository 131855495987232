import React, { useState, useEffect, useContext } from "react";
import { useMutation, ClientContext } from "graphql-hooks";

import { useNavigate, useLocation } from "react-router-dom";
//import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Divider,
  Tooltip,
  Button,
  Link,
  List,
  ListSubheader,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import styled from "styled-components";
import SideMenu from "../components/SideMenu.js";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: "0px, -45px, 20px, -25px",
          },
        },
      },
    }}
  />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
  }
`;
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginLeft: "50px",//"8rem",
    //  marginRight: "8rem",
    marginTop: "8rem",
    textAlign: "center",
    padding: theme.spacing(2),
    paddingRight: 10,
  },
  container: {
    marginTop: "1rem",
    marginBottom: "5rem",
    overflow: "hidden",
    clear: "both",
    //  height: "936px",
    borderRadius: "8px",
  },
  expColIcon: {
    backgroundColor: "#fff",
    color: "#0D47A1",
    border: "1px solid lightGray",
    position: "absolute",
    width: 30,
    height: 30,
    "&:hover": {
      backgroundColor: "#1E88E5",
      color: "#fff",
      border: "none",
    },
  },
}));

const applicantMenu = [
  {
    subheader: "Dashboard",
    icon: "Home",
    href: "/cul",
    items: [],
  },

  {
    subheader: "Assets List",
    icon: "",
    href: "/cul/assets-list",
    items: [],
  },

  {
    subheader: "Unzip Assets",
    icon: "",
    href: "/cul/TBD",
    items: [],
  },
  {
    subheader: "Import Assets",
    icon: "",
    href: "/cul/TBD",
    items: [],
  },

];


function AssetsSideMenu({ selectedMenu, selectedSubMenu  }) {
  const client = useContext(ClientContext);
  const navigate = useNavigate();
  const [pathname] = useState(window.location.pathname);
  // const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
  //   onError: (err) => {
  //     console.log(err);
  //   },
  // });
  // useEffect(() => {
  //   if (localStorage.getItem("refreshToken")) {
  //     onRefreshToken();
  //   } else navigate(`/ie/signin/?next=${pathname}`);
  // }, []);
  // const onRefreshToken = async () => {
  //   const { data: refreshData, error: refreshError } = await refreshToken({
  //     variables: { refreshToken: localStorage.getItem("refreshToken") },
  //   });
  //   if (refreshError) {
  //     if (
  //       refreshError.graphQLErrors[0].message ===
  //       "You do not have permission to perform this action"
  //     )
  //       navigate(`/ie/signin/?next=${pathname}`);
  //     if (refreshError.graphQLErrors[0].message === "Signature has expired")
  //       navigate(`/ie/signin/?next=${pathname}`);
  //   }
  //   if (refreshData && refreshData.refreshToken) {
  //     localStorage.setItem("token", refreshData.refreshToken.token);
  //     localStorage.setItem(
  //       "refreshToken",
  //       refreshData.refreshToken.refreshToken
  //     );
  //     client.setHeader(
  //       "Authorization",
  //       `JWT ${refreshData.refreshToken.token}`
  //     );
  //   }
  // };

  //console.log("selectedMenu___", selectedMenu);
  const classes = useStyles();
  const location = useLocation();

  const [menuStatus, setMenuStatus] = useState({
    simple: selectedMenu ? selectedMenu : "Dashboard",
    expand: null,
  });

  const [subMenu, setSubMenu] = useState({
    menu: selectedMenu,
    sub: selectedSubMenu,
  });

  useEffect(() => {
    if (location.pathname.indexOf("/ie/applicant/settings") != -1) {
      setMenuStatus({
        simple: "General Settings",
        expand: null,
      });
      setSubMenu({
        menu: "General Settings",
        sub: "Applicant Configuration",
      });
    } else if (location.pathname.indexOf("/ie/applicant/dashboard") != -1 ||
    (location.pathname.indexOf("/ie/applicant/profile") != -1)) {
      setMenuStatus({
        simple: "Dashboard",
        expand: null,
      });
      setSubMenu({
        menu: "Dashboard",
        sub: null,
      });
    }
  }, [location]);

  // console.log(
  //   "simple_applicant",
  //   selectedMenu,
  //   selectedSubMenu,
  //   menuStatus,
  //   menuStatus.expand,
  //   subMenu
  // );
  return (
    <div
      className={classes.cardContainer}
      //style={{ marginRight: simple ? "8rem" : "1rem" }}
    >
      <div className={classes.container}>
        <Card
          style={{
            width: menuStatus.simple ? 75 : 180,
            minHeight: 936,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Box pt={0} style={{ width: menuStatus.expand ? "93%" : "80%" }}>
              {applicantMenu.map((menu) => (
                <SideMenu
                  subheader={menu.subheader}
                  items={menu.items}
                  href={menu.href}
                  icon={menu.icon}
                  menuStatus={menuStatus}
                  subMenu={subMenu}
                  menuStatusFunc={(item) => setMenuStatus(item)}
                  subMenuFunc={(item) => setSubMenu(item)}
                />
              ))}
            </Box>
            <Box pt={3}>
              <IconButton className={classes.expColIcon}>
                {menuStatus.simple && (
                  <StyledTooltip title="Expand menu">
                    <KeyboardArrowRightIcon
                      onClick={() => {
                        setMenuStatus({
                          simple: null,
                          expand: menuStatus.simple,
                        });
                      }}
                    />
                  </StyledTooltip>
                )}
                {menuStatus.expand && (
                  <StyledTooltip title="Collapse menu">
                    <KeyboardArrowLeftIcon
                      onClick={() => {
                        if (subMenu.menu === menuStatus.expand)
                          setMenuStatus({ simple: menuStatus.expand, expand: null });
                         else
                          setMenuStatus({ simple: subMenu.menu, expand: null });
                      }}
                    />
                  </StyledTooltip>
                )}
              </IconButton>
            </Box>
          </Box>
        </Card>
      </div>
    </div>
  );
}

export default AssetsSideMenu;
