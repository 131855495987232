import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMutation, useManualQuery, ClientContext } from "graphql-hooks";

import {
  Container,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  Radio,
  Checkbox,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";

import styled from "styled-components";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AlertDialog from "../../components/AlertDialog.js";
var findAnd = require("find-and");
const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: "0px, -50px, 20px, -25px",
          },
        },
      },
    }}
  />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "50px",
    textAlign: "center",
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    width: "1614px",
    paddingTop: "9rem", //"7.5rem",
    //  minWidth: "500px",
  },
  container: {
    padding: theme.spacing(2),
    marginTop: "1rem",
    marginBottom: "5rem",
    overflow: "hidden",
    clear: "both",
    borderRadius: "8px",
    height: "902px",
    backgroundColor: "#ffffff",
  },
  autoComStyle: {
    "& .MuiFormControl-root": {
      width: 200,
      //  marginTop: 15,
      marginBottom: 2,
      "& .MuiInputLabel-outlined": {
        fontSize: 14,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      fontSize: 14,
    },
  },
  dataGridStyle: {
    "&.MuiDataGrid-scrollbar MuiDataGrid-scrollbar--horizontal css-1rtad1": {
      position: "relative",
      display: "block",
    },
    "&.MuiDataGrid-root": {
      //  minWidth: 980,
      //  width:"100%"
      //  overflowX: 'scroll'
    },
    "& .MuiDataGrid-cell": {
      display: "flex",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
      fontSize: 12,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
}));

const assetsList = [
  {
    assetId: 1,
    assetFileName: "Contratto fornitura e montaggio.pdf",
    section: "Corporate Assets",
    assetSubtype: "Battle Card",
    attachments: ["WALL-blue.pdf", "WALL-white.pdf"],
    uploadStatus: "Successful",
    dateUploaded: "10/18/2024",
    assetKey: "8dw53220efgsjx0pucf",
    assetName: "Contratto",
    assetDescription: "",
    assetStatus: "Approved",
  },
  {
    assetId: 2,
    assetFileName: "dem_wolters_kluwer_prod 4.html",
    section: "Corporate Assets",
    assetSubtype: "Email",
    attachments: [],
    uploadStatus: "Ready",
    dateUploaded: "",
    assetKey: "",
    assetName: "dem wolters",
    assetDescription: "",
    assetStatus: "",
  },
  {
    assetId: 3,
    assetFileName: "Collection Overview.pdf",
    section: "Dealer Assets",
    assetSubtype: "Uniform",
    attachments: [],
    uploadStatus: "Failed",
    dateUploaded: "10/18/2024",
    assetKey: "",
    assetName: "Collection",
    assetDescription: "",
    assetStatus: "",
  },
  {
    assetId: 4,
    assetFileName: "CULLIGAN - BOLOGNA.pdf",
    section: "Dealer Assets",
    assetSubtype: "Vehicle Fleet",
    attachments: ["locker-blu.pdf"],
    uploadStatus: "Successful",
    dateUploaded: "10/18/2024",
    assetKey: "zl5i11pkv9b353syb6h",
    assetName: "CULLIGAN",
    assetDescription: "",
    assetStatus: "Not Approved",
  },
  {
    assetId: 5,
    assetFileName: "PFAS Home.jpg",
    section: "Digital Assets",
    assetSubtype: "Layout",
    attachments: ["Vetrate-uffici.pdf"],
    uploadStatus: "Successful",
    dateUploaded: "10/19/2024",
    assetKey: "8dw53220efgsjx0pucf",
    assetName: "PFAS Home",
    assetDescription: "",
    assetStatus: "Approved",
  },
  {
    assetId: 6,
    assetFileName: "PFAS_Ciclo.jpg",
    section: "Digital Assets",
    assetSubtype: "Organic Social",
    attachments: ["Vetrate-uffici.pdf"],
    uploadStatus: " Ready",
    dateUploaded: "",
    assetKey: "",
    assetName: "PFAS Ciclo",
    assetDescription: "",
    assetStatus: "",
  },
];
const assetsOption = [
  { label: "Italy" },
  { label: "EMEA" },
  { label: "Germany" },
];
const sectionOption = [
  { label: "All" },
  { label: "Corporate Assets" },
  { label: "Dealer Assets" },
  { label: "Digital Assets" },
];
const subtypeOptionInitValue = [
  { section: "All", label: "All" },
  { section: "Corporate Assets", label: "Battle Card" },
  { section: "Corporate Assets", label: "Email" },
  { section: "Dealer Assets", label: "Uniform" },
  { section: "Dealer Assets", label: "Vehicle Fleet" },
  { section: "Digital Assets", label: "Layout" },
  { section: "Digital Assets", label: "Organic Social" },
];
function AssetsList() {
  const classes = useStyles();
  const [assetSetValue, setAssetSetValue] = useState("Italy");
  const [sectionValue, setSectionValue] = useState("All");
  const [subtypeValue, setSubtypeValue] = useState("All");
  const [subtypeOption, setSubtypeOption] = useState(subtypeOptionInitValue);
  const [confirmAlertMsg, setConfirmAlertMsg] = useState([]);

  const [rowsList, setRowsList] = useState(
    assetsList.map((ass, index) => ({
      index,
      id: ass.assetId,
      assetFileName: ass.assetFileName,
      section: ass.section,
      assetSubtype: ass.assetSubtype,
      attachments: ass.attachments,
      uploadStatus: ass.uploadStatus,
      dateUploaded: ass.dateUploaded,
      assetKey: ass.assetKey,
      assetName: ass.assetName,
      assetDescription: ass.assetDescription,
      assetStatus: ass.assetStatus,
    }))
  );
  const [filterdRowsList, setFilteredRowsList] = useState(rowsList);
  //
  // useEffect(() => {
  //
  // }, [assetsList]);
  const formatter = new Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const dayFormat = (day) => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);

    const newFormat =
      dayFormat.find((a) => a.type == "month").value +
      "/" +
      dayFormat.find((a) => a.type == "day").value +
      "/" +
      dayFormat.find((a) => a.type == "year").value;
    return newFormat;
  };
  const columns = [
    {
      field: "assetFileName",
      headerName: "Asset File Name",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      //  flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "section",
      headerName: "Section",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      //  flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },

    {
      field: "assetSubtype",
      headerClassName: "super-app-theme--header",
      headerName: "Asset Sub-type",
      minWidth: 140,
      //  flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },

    {
      field: "attachments",
      headerClassName: "super-app-theme--header",
      headerName: "Attachments",
      minWidth: 170,
      //  flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rows) => (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {rows.row.attachments.map((attach) => (
            <Typography variant="h3">{attach}</Typography>
          ))}
        </Box>
      ),
    },

    {
      field: "uploadStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Upload Status",
      minWidth: 120,
      //  flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      // renderCell: rows => {
      //   return rows.row.status === "Published" ? (
      //     <Checkbox
      //       onChange={handleCheckBoxChange}
      //       id={rows.row.id}
      //       defaultChecked={rows.row.checked}
      //       disabled={rows.row.disabled}
      //     />
      //   ) : (
      //     ""
      //   );
      // },
    },
    {
      field: "dateUploaded",
      headerClassName: "super-app-theme--header",
      headerName: "Date Uploaded",
      minWidth: 120,
      //  flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "assetKey",
      headerClassName: "super-app-theme--header",
      headerName: "Asset key",
      minWidth: 170,
      //  flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "assetName",
      headerClassName: "super-app-theme--header",
      headerName: "Asset Name",
      minWidth: 100,
      //  flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "assetDescription",
      headerClassName: "super-app-theme--header",
      headerName: "Asset Description",
      minWidth: 140,
      //  flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "assetStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Asset Status",
      minWidth: 120,
      //    flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 50,
      //    flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rows) => {
        return (
          <Box
            //pt={1}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <StyledTooltip
              title={
                rows.row.uploadStatus === "Successful"
                  ? "Upload Successful"
                  : "Upload Asset"
              }
            >
              <IconButton
                style={{
                  cursor:
                    rows.row.uploadStatus === "Successful"
                      ? "default"
                      : "pointer",
                }}
              >
                <UploadFileIcon
                  color={
                    rows.row.uploadStatus === "Successful"
                      ? "disabled"
                      : "action"
                  }
                  onClick={() =>
                    //handleUploadAsset(rows.row.id)
                    rows.row.uploadStatus !== "Successful" &&
                    setConfirmAlertMsg({
                      title: "Upload Asset",
                      type: "Confirmation",
                      msg:
                        "Are you ready to upload the “" +
                        rows.row.assetFileName +
                        "” asset?",
                      confirmButton: "Upload",
                      id: rows.row.id,
                    })
                  }
                />
              </IconButton>
            </StyledTooltip>

            <StyledTooltip title="Upload log activity">
              <IconButton>
                <PendingActionsIcon color="action" />
              </IconButton>
            </StyledTooltip>
          </Box>
        );
      },
    },
  ];
  function generateRandomString(length) {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }
  function DataGridTitle() {
    return (
      <>
        <Grid
          container
          xs={12}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h1" style={{ fontSize: 20 }}>
              Brandfolder Assets List
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </>
    );
  }

  const handleChangeAssetsOptionValue = (value) => {
    setAssetSetValue(value);
  };
  const handleChangeSectionOptionValue = (val) => {
    const value = val ? val : "All";
    setSectionValue(value);
    setSubtypeValue("All");
    if (value == "All") {
      // set init values
      setFilteredRowsList(rowsList);
      setSubtypeOption(subtypeOptionInitValue);
    } else {
      // set filtered values
      const draftList = rowsList.filter((r) => r.section === value);
      const subtypeOptionList = subtypeOptionInitValue.filter(
        (sub) => sub.section === value || sub.section === "All"
      );
      setSubtypeOption(subtypeOptionList);
      setFilteredRowsList(draftList);
    }
  };
  const handleChangesubtypeOptionValue = (val) => {
    const value = val ? val : "All";
    setSubtypeValue(value);
    let sectionFilteredList = rowsList;
    if (sectionValue && sectionValue !== "All")
      sectionFilteredList = rowsList.filter((r) => r.section === sectionValue);
    if (value === "All") {
      setFilteredRowsList(sectionFilteredList);
    } else {
      const draftList = sectionFilteredList.filter(
        (r) => r.assetSubtype === value
      );
      setFilteredRowsList(draftList);
    }
  };
  const handleUploadAsset = (id) => {
    let updatedRowsList = findAnd.changeProps(
      filterdRowsList,
      { id: id },
      {
        uploadStatus: "Successful",
        dateUploaded: new Date().toLocaleDateString(), //dayFormat(frm.createDate)
        assetKey: generateRandomString(19),
        assetStatus: "Not Approved",
      }
    );
    setFilteredRowsList(updatedRowsList);
  };
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item>
            <Autocomplete
              options={assetsOption.map(({ label }) => label)}
              //disableClearable
              fullWidth
              value={assetSetValue}
              onChange={(event, value) => {
                handleChangeAssetsOptionValue(value);
              }}
              className={classes.autoComStyle}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  //  required={true}
                  fullWidth
                  label="Select Assets Set"
                  margin="normal"
                  name="selectAssets"
                  id="selectAssets"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              options={sectionOption.map(({ label }) => label)}
              //  disableClearable
              fullWidth
              value={sectionValue}
              onChange={(event, value) => {
                handleChangeSectionOptionValue(value);
              }}
              className={classes.autoComStyle}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  //    required
                  fullWidth
                  label="Select Section"
                  margin="normal"
                  name="selectAssets"
                  id="selectAssets"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              options={subtypeOption.map(({ label }) => label)}
              //disableClearable
              fullWidth
              value={subtypeValue}
              onChange={(event, value) => {
                handleChangesubtypeOptionValue(value);
              }}
              className={classes.autoComStyle}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  //  required
                  fullWidth
                  label="Select Asset Sub-type"
                  margin="normal"
                  name="selectAssets"
                  id="selectAssets"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box pt={6}>
          {assetSetValue === "Italy" ? (
            <DataGrid
              className={classes.dataGridStyle}
              columns={columns}
              rows={filterdRowsList}
              showCellRightBorder={true}
              hideFooter={true}
              //  autoWidth={true}
              slots={{
                toolbar: DataGridTitle,
              }}
              getRowId={(row) => row.id}
              autoHeight={true}
              experimentalFeatures={{ newEditingApi: true }}
            />
          ) : (
            <Typography variant="h3">
              We are going to show {assetSetValue} Asset Set list...
            </Typography>
          )}
        </Box>
        {confirmAlertMsg.msg && (
          <AlertDialog
            title={confirmAlertMsg.title}
            type={confirmAlertMsg.type}
            contentMsg={confirmAlertMsg.msg}
            closeDialog={() => setConfirmAlertMsg([])}
            confirmAction={() => {
              setConfirmAlertMsg([]);
              handleUploadAsset(confirmAlertMsg.id);
            }}
            confirmButton={confirmAlertMsg.confirmButton}
          />
        )}
      </div>
    </div>
  );
}

export default AssetsList;
