import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useMutation, ClientContext, GraphQLClient } from "graphql-hooks";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core";
import typography from "./theme/typography";
import palette from "./theme/palette";

import Header from "./components/Header";
import Footer from "./components/Footer";
import AssetsSideMenu from "./userPages/AssetsSideMenu";
import AssetsList from "./userPages/brandfolderAssets/AssetsList";
import Home from "./userPages/Home";
import ToBeDev from "./userPages/ToBeDev";

const theme = createMuiTheme({
  shadows: ["none"],
  typography,
  palette,
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F5F5F5",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    //  flex: "1 1 auto",
    overflow: "auto",
    minHeight: "100vh",
    minWidth: "1790px", //1500 IE-111 SP
  },
  sideMenuWrapper: {
    display: "flex",
    flexDirection: "row",
    minWidth: "1616px",
  },
  wrapper: {
    overflow: "auto",
    flex: "1 1 auto",
    paddingTop: "6rem",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function App() {
  const classes = useStyles();
  const getToken = () => localStorage.getItem("token");
  const token = getToken();
  const [sideMenu, setSideMenu] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [previewPage, setPreviewPage] = useState(false);
  const [publicPage, setPublicPage] = useState(false);

  const client = new GraphQLClient({
    // url: "http://localhost:8000/cul/graphql",
    // // useGETForQueries: false,
    url: "/cul/graphql",
    headers: { Authorization: token ? `JWT ${token}` : "" },
    fetchOptions: () => {
      return {
        headers: { Authorization: token ? `JWT ${token}` : "" },
      };
    },
  });

  const HandleHome = ({ homeTarget }) => {
    setSideMenu(null);
    return <Home />;
  };
  const AssetsPageLayout = () => {
    const location = useLocation();
    setSideMenu("assetsMenu");
    setSelectedMenu("Assets List");
    if (location.pathname.indexOf("/cul/assets-list") != -1)
      return <AssetsList />;
  };
  ////////////////////////
  return (
    <ClientContext.Provider value={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className={classes.root}>
            <Header />
            <div
              className={
                sideMenu
                  ? classes.sideMenuWrapper
                  : !publicPage
                  ? classes.wrapper
                  : null
              }
            >
              {sideMenu && sideMenu === "assetsMenu" && (
                <AssetsSideMenu selectedMenu="Assets List" />
              )}

              <Routes>
                <Route path="*" element={<HandleHome />} />
                <Route path="/cul/assets-list" element={<AssetsPageLayout />} />
                <Route path="/cul/TBD" element={<ToBeDev />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </ClientContext.Provider>
  );
}
ReactDOM.render(<App />, document.getElementById("root"));
