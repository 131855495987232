import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Paper } from "@mui/material";
import { Link, Typography, makeStyles } from "@material-ui/core";

const footerData = [
  {
    label: "About Us",
    href: "/about-us.html",
  },
  {
    label: "Privacy Policy",
    href: "/privacy-policy.html",
  },
  {
    label: "Terms of Use",
    href: "/terms-of-use.html",
  },
];

const footerDataMobile = [
  {
    label: "About Us",
    href: "/about-us.html",
  },
  {
    label: "Privacy",
    href: "/privacy-policy.html",
  },
  {
    label: "Terms",
    href: "/terms-of-use.html",
  },
];
const useStyles = makeStyles(() => ({
  footer: {
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, sans-serif",
    color: "#000000DE",
    backgroundColor: "#FFFEFE",
    height: "64px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: "79px",
    paddingLeft: "142px",
  },
  footerMobile: {
    fontSize: "10px",
    fontFamily: "Roboto, Helvetica, sans-serif",
    color: "#000000DE",
    backgroundColor: "#FFFEFE",
    //height: "110px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    //justifyContent: "space-between",
    paddingRight: "0px",
    paddingLeft: "5px",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  copyStyle: {
    color: "#000000DE",
    backgroundColor: "#FFFEFE",
    alignItems: "center",
    paddingRight: "79px",
    paddingLeft: "142px",
  },
  copyStyleMobile: {
    color: "#000000DE",
    backgroundColor: "#FFFEFE",
    paddingTop: "15px",
  },
}));

const getFooterItem = (device = "desktop") => {
  let data = footerData;
  if (device === "mobile") data = footerDataMobile;
  return data.map(({ label, href }, index) => {
    return (
      <Link
        {...{
          component: RouterLink,
          to: href,
          color: "inherit",
          style: {
            textDecoration: "none",
            fontSize: 12,
            paddingRight: index !== data.length - 1 ? 30 : 0,
          },
          key: label,
        }}
      >
        {label}
      </Link>
    );
  });
};
const Footer = () => {
  const { footer, footerMobile, copyStyle, copyStyleMobile } = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 1110 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <Paper className={mobileView || tabletView ? footerMobile : footer}>
      {mobileView && <div>{getFooterItem("mobile")}</div>}
      {tabletView && <div>{getFooterItem()}</div>}
      {!tabletView && !mobileView && <div> {getFooterItem()}</div>}
    </Paper>
  );
};

export default Footer;
