import React, { useState, useEffect, useContext } from "react";
import { useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const client = useContext(ClientContext);
  const navigate = useNavigate();
  const [pathname] = useState(window.location.pathname);


  return (
    <div style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
    <h1 style={{ textAlign: "center" }}>Welcome to DAM Journey</h1>
    <br />
    <h4 style={{ textAlign: "center" }}>Stay tuned! We are working to launch our web site.</h4>

    </div>
  );
};
export default Home;
